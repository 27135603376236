.post-wrapper {
	@include span-columns(12);
	margin-bottom: 30px;

	a {
		font-weight: bold;
		color: color('orange');
	}

	h2, h1 {
		color: color('orange');
		font-weight: bold;
		display: block;
		font-size: 16px;
		text-transform: uppercase;
	}

	h3 {
		display: block;
		padding-top: 5px;
		font-size: 14px;
		line-height: 18px;
	}

	.std {
		@include span-columns(12);
		margin: 10px 0;
	    font-size: 12px;
	    line-height: 16px;	
	    text-align: left;

	    img {
	    	width: 100%;
	    	margin-bottom: 20px;
	    }	

	    .aw-blog-read-more {
	    	@include span-columns(12);
	    	margin: 10px 0 0 0;
	    }

	}

	.tags {
		display: inline-block;
		margin-bottom: 5px;

		li {
			display: inline-block;
		}

	}

	.poster {
		display: inline-block;
	}

}
