.cms-institutional {

	.page-title {
		@extend .no-display;
	}
	
	.std {
		@include span-columns(12);
		font-size: 12px;
		line-height: 16px;
		margin: 20px 0;

		table {

			td {
				padding-bottom: 20px;
			}

		}

		img {
			width: 100%;
		}

	}

}

.cms-menu {
	@include span-columns(12);
	@extend .vertical-scroll;	
	background: rgba(color('white'), 0.5);
	margin: 0 0 20px 0;
	border-bottom: 4px solid darken(color('gray', 'light'), 4%);

	ul {
		@include outer-container();

		li {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			padding: 20px 20px 16px 50px;
			font-size: 14px;
			text-transform: uppercase;
			text-align: center;
			background: transparent;
			border-right: 1px solid darken(color('gray', 'light'), 4%);

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -4px;
				width: 100%;
				height: 4px;
			}

			a {
				color: color('gray');
			}

			&.active {
				background: color('white');
				color: color('orange');

				&:after {
					background: color('orange');
				}					

			}

			span {
			    position: absolute;
			    top: 14px;
			    font-size: 26px;
			    margin-left: -35px;
			}

			&.category {
				background: color('green');

				&:after {
					background: color('green');
				}

				a {
					color: color('white');
				}

			}

		}

	}

}

.cms-sidebar {
	@include span-columns(12);
	margin-bottom: 60px;

	.block-title {
		@extend .no-display;
	}

	.block-content {

		li {
			position: relative;
			border-bottom: 1px solid color('gray', 'dark');
			font-size: 16px;
			text-transform: uppercase;
			padding: 16px $gapp;

			&:first-child {		
				border-top: 1px solid color('gray', 'dark');
			}

			&:after {
				font-family: 'icomoon';
				content: '\e90f';
				position: absolute;
				right: 0;
				top: 0;
				color: color('gray', 'dark');
				padding: 18px 10px;
				font-size: 12px;
		        transform: rotate(-90deg); 					
			}

			a {
				color: color('gray', 'dark');

				strong {
					font-weight: normal;
				}

			}

			&.current {

				&:after {
					transform: rotate(0deg);
				}

			}

		}

	}

}