%section-title {
    background: color('gray', 'semi-light');
    color: color('gray', 'dark');

    a {
        display: none;
    }    

}

%section-title-allow {
    background: color('black');
    color: color('white');

    a {
        display: block;
    }

}

%section-title-active {
    background: color('gray', 'semi-light');
    color: color('gray', 'dark');

    a {
        display: none;
    }

}

.section {
	@include span-columns(12);
	margin: 0 0 10px 0;

    &#opc-review {

        .step-title {
            @extend .no-display;
        }

    }

    .step-title {
    	@include span-columns(12);
        padding: 16px 10px;
        @extend %section-title;

        h2 {
            float: left;
            font-size: 16px;
            font-weight: bold;
        }

        a {
            float: right;
            font-weight: bold;
            font-size: 14px;
            color: color('white');
            line-height: 16px;

            span {
                position: relative;
                top: 1px;
                margin-right: 10px;
            }

        }

    }

    &.allow {
        
        .step-title {
            @extend %section-title-allow;
        }

    }

    &.active {

        .step-title {
            @extend %section-title-active;
        }

    }

    .step {
    	@include span-columns(12);
        padding: 10px;
        line-height: 16px;
        font-size: 14px;
        color: color('gray', 'dark');

        .back-link {
            @extend .no-display;
        }

        .col-1,
        .col-2 {
            @include span-columns(12);
        }

        .col-3 {
            @include span-columns(12);
        }

        h3, h4 {
            font-size: 14px;
            color: color('orange');
            margin: 0 0 10px 0;
        }

        h4 {
            font-size: 14px;
        }

        &#checkout-step-login {
        	
        	.col-1,
        	.col-2 {
        		@include span-columns(12);
        	}

        	h3, p, form, .buttons-set {
        		@include span-columns(12);
        	}

        	h3 {
        		text-transform: uppercase;
        	}

        	.box-password {
        		margin-bottom: 10px;
        	}

        	.buttons-set {
                margin: 20px 0;
        	}

        }

        &#checkout-step-billing,
        &#checkout-step-shipping {

            .box-address-select {

                p {
                    font-size: 12px;
                    display: block;
                    margin-bottom: 10px;
                }

                select {
                    margin-bottom: 10px;
                }

            }

            .default-address {
                @include span-columns(12);
                margin: 0 0 10px 0;
                padding: $gapp;
                font-size: 12px;
                line-height: 16px;
                background: color('gray', 'light');

                strong {
                    display: block;
                    font-weight: normal;
                    font-size: 14px;
                    color: color('orange');
                }

            }

            .form-list {
                @include span-columns(12);
                margin: 20px 0 0 0;
            }

            .change-shipping {
                @include span-columns(12);
                margin: 20px 0 0 0;                
            }

        }

        &#checkout-step-shipping_method {

            .sp-methods {

                & > li {
                    margin-bottom: 10px;

                    label {
                        line-height: 30px;
                    }
                    
                    strong {
                        color: color('gray', 'dark');
                    }

                    .error-msg {
                        margin-bottom: 20px;
                    }

                }

            }

            .gift-messages,
            .gift-messages-form {
                @include span-columns(12);
            }   

            .gift-messages-form {

                .inner-box {
                    margin-top: 30px;
                }

                p {
                    @include span-columns(12);
                    padding: 0 0 20px 0;
                }

                .item {
                    @include span-columns(12);
                    margin-bottom: 20px;
                    padding: 20px 20px 0 20px;
                    background: color('gray', 'light');

                    .number,
                    .product-image {
                        @extend .no-display;
                    }

                    .product-name {
                        text-transform: uppercase;
                        font-weight: bold;
                        margin-bottom: 15px;
                        color: color('orange');
                    }

                    textarea {
                        resize: none;
                    }

                }

            }         

        }

        &#checkout-step-payment {

            .sp-methods {
                
                dt {
                    @include span-columns(12);

                    label {
                        display: block;
                        margin-bottom: 10px;
                    }

                }

                dd {
                    @include span-columns(12);
                }

            }

            .totals {
                @include span-columns(12);
                background: color('gray', 'light');
                margin-top: 20px;

                table {
                    font-size: 14px;
                    line-height: 18px;

                    tr {

                        td {
                            padding: 5px $gapp;

                            &:last-child {
                                text-align: right;
                                font-weight: bold;
                            }

                        }

                        &:last-child {

                            td {
                                color: color('orange');
                                font-size: 16px;

                                &:first-child {

                                    strong {
                                        font-weight: normal;
                                    }

                                }

                            }

                        }

                    }

                }

            }

            .buttons-set {
                
                .button, .please-wait {
                    @include span-columns(12);
                }

            }

            @import "billet.scss";       
            @import "card.scss";
            @import "debt.scss";

        }

        .buttons-set {
            @include span-columns(12);
            margin-top: 10px;

            .button, .please-wait {
                @include span-columns(12);
            }

            .button {
                @extend %green;
            }
            
            .please-wait {
                padding-top: 20px;
                text-align: center;

                img {
                    display: block;
                    margin: 0 auto;
                }

            }

        }

    }

}
