.cms-no-route {

	.col-main {
		position: relative;
	}

	.page-title, .std {
		@include span-columns(12);
		margin: 20px 0;
	}

	.page-title {
		padding-bottom: 10px;
		margin-bottom: 0;
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			font-size: 16px;
			padding-bottom: 0;
			color: color('orange');
		}

	}

	.std { 
		font-size: 12px;
		line-height: 16px;

		#search_mini_form {
			@include span-columns(12);
			margin: 10px 0;

			.input-box {
				@include span-columns(12);
				margin-bottom: 0;
				position: relative;

				.search-button {
				    position: absolute;
				    right: 2px;
				    top: 2px;
				    width: 40px;
				    height: 37px;
				    padding: 0;
				    line-height: 42px;
				    background: color('orange');
				}

			}

		}

		a {
			text-decoration: underline;
		}

		img {
			@extend .no-display;
		}

	}

	.tabs-home {
		@include span-columns(12);
		position: relative;
		margin-top: 10px;

		.tabs-block {
			padding: 0 0 15px 0;
			text-transform: uppercase;
			color: color('orange');
			border-bottom: 1px solid color('gray');

			h2 {
				font-size: 14px;					
			}

		}

		.loader {
			margin: 20px auto;
		}

	}

}