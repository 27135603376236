.block-title {
	@extend .no-display;
}

.block-content {

	li {
		position: relative;
		border-bottom: 1px solid color('gray', 'dark');
		font-size: 16px;
		text-transform: uppercase;
		padding: 16px $gapp;

		&:first-child {		
			border-top: 1px solid color('gray', 'dark');
		}

		&:after {
			font-family: 'icomoon';
			content: '\e90f';
			position: absolute;
			right: 0;
			top: 0;
			color: color('gray', 'dark');
			padding: 18px 10px;
			font-size: 12px;
	        transform: rotate(-90deg); 					
		}

		a {
			color: color('gray', 'dark');

			strong {
				font-weight: normal;
			}

		}

		&.current {

			&:after {
				transform: rotate(0deg);
			}

		}

	}

}