.customer-account-forgotpassword,
.customer-account-changeforgotten {

	.page-title {
		@include span-columns(12);
		margin: 20px 0;
		text-align: center;

		h1 {
			color: color('orange');
			padding-bottom: 5px;
		}

		p {
			font-size: 16px;
		}

	}

	.main {
		margin: 0 0 20px 0;
	}

}