ul {
	@include span-columns(12);
	background: color('black');
	text-transform: uppercase;
	font-size: 13px;

    li {
    	border-top: 1px solid color('black', 'light');
    	padding: 0 $gapp;

	    a {
	    	display: block;
    		padding: 16px 0;	    	
	    	color: color('white');
	    }

    }

}