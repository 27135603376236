.checkout-onepage-success {

	.main-container {
    	@include outer-container(100%);
    	text-align: center;
    	
    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

    	.align-container {
    		@include outer-container();
    		padding: 0 $gapp;
    	}

    }

    .page-title {
    	@include span-columns(12);
    	margin: 20px 0;

	    h1 {
            font-size: 16px;
	    	color: color('orange');
	    }

	    p {
	    	font-size: 12px;
            line-height: 16px;
	    }

    }

    .box-info {
    	background: color('gray', 'semi-light');
    	padding: 20px 0;

    	h2 {
    		color: color('orange');
    		font-weight: bold;
    		text-transform: uppercase;
    		font-size: 16px;
    		padding-bottom: 20px;

    		a {
    			color: color('orange');
    		}

    	}

    	p {
    		font-size: 12px;
    		line-height: 16px;
    	}

    	.buttons-set {
            @include span-columns(12);
    		margin: 20px 0;

    		.button {
    			@include span-columns(12);
                margin-bottom: 20px;

                &.blue {
                    color: color('white');
                    background: color('orange');
                }

    		}

    	}

    }

}