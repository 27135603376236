.orders-history {
	@include span-columns(12);
	margin: 0 0 40px 0;	
	font-size: 14px;
	line-height: 20px;

	.pager  {
		@include span-columns(12);
		margin-bottom: 10px;

		.amount  {
			@extend .no-display;
		}

		.pages { 
			@include span-columns(6);
		}

		.limiter {
			@include span-columns(6);
			margin-right: 0;
			float: right;

			label {
				@include span-columns(6);
				text-transform: uppercase;
				font-weight: bold;
				text-align: right;
				padding-right: 10px;
			}

			select {
				@include span-columns(6);
				position: relative;
				top: -10px;
			}

		}

	}

	table {

		th, td {

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				@extend .no-display;
			}

		}

		th {
			text-transform: uppercase;
			font-weight: bold;
			color: color('orange');
			padding: 10px 0;
		}	

		tr {

			td {
				padding: 10px 0;
				border-bottom: 2px solid color('gray', 'light');

				a {
					font-weight: bold;
					color: color('orange');
					text-decoration: underline;
					text-transform: lowercase;
				}

			}

		}

	}

}

.order-info {
	@include span-columns(12);
	font-size: 16px;
	line-height: 20px;
	margin: 0;

	.buttons {
		@include span-columns(12);
		margin-bottom: 30px;
		text-align: right;

		a {
			font-weight: bold;
			color: color('orange');
			text-decoration: underline;
			text-transform: lowercase;
		}

		.separator,
		.link-reorder {
			@extend .no-display;
		}

	}

	.box {

		.box-title {

			h2 {
				@include span-columns(12);
				margin-bottom: 0;
			}

		}

		.box-content {

			strong {
				font-weight: normal;
			}

		}

	}

}

.order-view {
	@include span-columns(12);
	margin: 0 0 20px 0;
	font-size: 14px;
	line-height: 20px;
	padding-top: 0;

	h3 {
		font-size: initial;
		color: initial;
		text-transform: initial;
		font-weight: normal;
		padding-bottom: 0;
		margin-bottom: 0;
		letter-spacing: initial;
		white-space: initial;
	}

	table {
		@extend .vertical-scroll;

		th {
			text-transform: uppercase;
			font-weight: bold;
			color: color('orange');
			padding: 10px 15px 10px 15px;
		}	

		tr {

			td {
				color: initial;
				text-transform: initial;
				font-weight: normal;
				padding-bottom: 0;
				letter-spacing: initial;				
				padding: 15px;
				border-bottom: 2px solid color('gray', 'light');

				a {
					font-weight: bold;
					color: color('orange');
					text-decoration: underline;
					text-transform: lowercase;
				}

			}

		}

		tbody,
		thead {

			td, th {
				text-align: left;

				&:first-child {
					padding-left: 0;
					text-align: left;
				}

				&:last-child {
					text-align: right;
				}				

			}

		}

		tfoot {
			background: color('gray', 'light');
			
			td {
				text-align: right;
				padding: 10px 15px;
			}

		}
		
	}

}