.footer-container {
	@include span-columns(12);
	color: color('white');
	background: color('black');
	background-size: cover;

	.footer {
		@include outer-container;
		padding: 15px $gapp 30px $gapp;

		.newsletter {
			@include span-columns(12);
			padding: 20px $gapp;
			background: rgba(color('white'), 0.1);
			@import "components/footer/newsletter.scss";
		}

		.links {
			@include span-columns(12);
			padding: 20px 30px 0 30px;
			@import "components/footer/links.scss";
		}

		&.onepage {
			padding: 20px $gapp;
			@import "components/footer/onepage.scss";
		}

	}

	.copyright {
		@include span-columns(12);
		background: color('white');
		color: color('black');
		@import "components/footer/copyright.scss";
	}

	.payment {

		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 10px;
		}

	}

	#armored_website {
		width: 100% !important;

		img {
			margin: 0 auto;
		}

	}	

}