.checkout-onepage-index {

    .col2-left-layout {

		.main {
			padding: 0 $gapp;
			margin-bottom: 30px;
		}

        .col-right {
            @include span-columns(12);
            background: color('gray', 'semi-light');
            padding: $gapp;
        }

        .col-main {
            @include span-columns(12);
        }

    }

	.page-title {
		@include span-columns(12);
		margin: 20px 0 15px 0;
		text-align: center;

		h1 {
			@include span-columns(12);
			font-size: 16px;
			color: color('orange');
			padding: 5px 0;

			span {
				margin-right: 10px;
			    position: relative;
			    top: 3px;				
			}

		}

	}

	.block-progress {
		@import "components/checkout/progress.scss";
	}

	.opc {
		@include span-columns(12);
		@import "components/checkout/opc.scss";
	}

}