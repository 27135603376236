.bar {
	@include span-columns(12);
	@extend .vertical-scroll;
	line-height: 30px;
	background: color('gray', 'light');
	text-align: center;
	border-bottom: 1px solid color('gray');

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 20px;
		text-transform: uppercase;
		border-right: 1px solid color('gray');

		&:last-child {
			border-right: 0;
		}

		span {
			color: color('orange');
			margin-right: 15px;
			font-size: 15px;
			vertical-align: middle;
		}

	}

}

.bar-notlogin {
	@include span-columns(12);
	text-align: center;

	li {
		@include span-columns(12);
		padding: 10px 40px;
		font-size: 12px;
		line-height: 16px;
		position: relative;
		border-bottom: 1px solid color('gray');

		&:last-child {
			border-bottom: 0;
		}

		strong {
			display: block;
			padding-top: 10px;
			font-size: 16px;
			font-weight: normal;
			text-transform: uppercase;
			color: color('black');
		}

		img {
			display: block;
			margin: 20px auto;
		}

		a {
			margin: 20px 0;
			font-size: 14px;
			font-weight: normal;
			@extend %black;

			&:hover {
				color: color('white');
			}

		}

	}

}