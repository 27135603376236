.checkout-cart-index {

	.page-header {

		.menu {

			span {
				background: color('orange');
			}

		}

	}

	.page-title {
		@include span-columns(12);
		margin: 20px 0 0 0;
		border-bottom: 1px solid color('gray');

		h1 {
			font-size: 16px;
			color: color('orange');
			font-weight: normal;
			padding-bottom: 10px;
		}

	}

	.add-to {
		@include span-columns(12);
		margin: 20px 0;

		.buybycart-suggestion {

			ul {
				@include span-columns(12);
				margin: 20px 0;
				padding: $gapp;
				background: color('gray');

				li {
					float: none;
					display: block;
					padding: 5px 0;
				}

			}

		}
					
	}

	.checkout-buttons {
		@include span-columns(12);
		margin: 20px 0;

		li {
			@include span-columns(6);

			&:last-child {
				float: right;

				button {
					@extend %green;
				}
				
			}

		}

	}

	.cart-empty {
		font-size: 12px;
		line-height: 16px;
		min-height: 200px;

		a {
			font-weight: bold;
			color: color('black');
		}

	}

	@import "components/cart/items.scss";
	@import "components/cart/box.scss";
}