.customer-account-create {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('orange');
			margin: 20px 0 5px 0;
		}

		p {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 20px;			
		}

	}

	h2 {
		@include span-columns(12);
		margin: 20px 0;
		color: color('orange');	
		text-transform: uppercase;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
	}

	.col-1, .col-2 {
		@include span-columns(12);
	}

	.col-1 {
		border-bottom: 2px solid color('orange');			

		h2 {
			margin-top: 0;	
		}

	}

	.buttons-set {
		@include span-columns(12);
		margin: 0 0 20px 0;
	}

	.phone-layout {

		.input-box {

			border: 1px solid rgba(139, 139, 139, 0.6);
			height: 48px;

			select {

				@include span-columns(4);
				margin-bottom: 20px;
				border: none;
				background-color: color('gray', 'light');
				width: 27%;
				padding: 13px 20px;
			}

			input {

				@include span-columns(8);
				margin-bottom: 20px;
				border: none;
				padding-bottom: 11px;
			}
		}
	}
}