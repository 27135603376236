.block-title {
	font-size: 16px;
	color: color('orange');
	padding-bottom: 20px;
}

.review-items {
	@include span-columns(12);

	tr {
		
		td {
			padding: 10px 0;
			border-bottom: 1px solid color('gray');

			&.product-info {
				font-size: 12px;
				line-height: 16px;
				color: color('black');

				span {
					display: block;
					margin-top: 5px;
					font-size: 10px;
					color: color('gray', 'dark');
				}

			}

			&.product-qty {
				text-align: center;
				font-size: 16px;
				width: 20%;
			}

			&.product-price {
				text-align: right;
				font-size: 16px;
				color: color('orange');
			}

		}

		&:first-child {
			
			td {
				padding-top: 0;
			}

		}

		&:last-child {

			td {
				border-bottom: 0;
			}

		}

	}

}

.progress {
	@include span-columns(12);
	font-size: 12px;
	@extend .no-display;

	dt {
		padding-bottom: 20px;
		color: color('gray', 'dark');
		
		a {
			margin-left: 5px;
			color: color('orange');
			font-size: 10px;
		}

		&.complete {
			padding-bottom: 10px;
			font-size: 12px;
			color: color('orange');
		}

	}

	dd {
		font-size: 12px;
		line-height: 16px;
		padding-bottom: 20px;
		color: color('gray', 'dark');
	}

}